const contributingPages = [
  {
    title: 'Library',
    path: '/contributing/library',
  },
  {
    title: 'Docs',
    path: '/contributing/docs',
  },
];
export default contributingPages;
