const utils = [
  {
    title: 'Anchor',
    path: '/utils/anchor',
  },
  {
    title: 'Color',
    path: '/utils/color',
  },
  {
    title: 'Hoverable',
    path: '/utils/hoverable',
  },
  {
    title: 'Shadow',
    path: '/utils/shadow',
  },
];
export default utils;
