const stylePages = [
  {
    title: 'Font',
    path: '/style/font',
  },
  {
    title: 'Icons',
    path: '/style/icons',
  },
  {
    title: 'Theme',
    path: '/style/theme',
  },
];

export default stylePages;
